var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.row.getRowCss() },
    _vm._l(_vm.elements, function (element) {
      return _c(
        "div",
        { style: element.rootStyle },
        [
          !element.isPanel
            ? _c("survey-element", {
                key: element.id,
                style: element.getRootStyle(),
                attrs: {
                  element: element,
                  survey: _vm.survey,
                  css: _vm.css,
                  row: _vm.row,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          element.isPanel
            ? _c("survey-panel", {
                key: element.id,
                attrs: { question: element, css: _vm.css },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }