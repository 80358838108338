





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Question, DropdownMultiSelectListModel } from "survey-core";
import BaseVue from "../../base";

@Component
export class TagboxComponent extends BaseVue {
  @Prop() question: Question;

  get model() {
    return this.question.dropdownListModel;
  }
  getModel() {
    return this.model;
  }
  inputChange(event: any) {
    this.model.filterString = event.target.value;
  }

  public click(event: any) {
    this.question.dropdownListModel?.onClick(event);
  }
  public clear(event: any) {
    this.question.dropdownListModel?.onClear(event);
  }
  public keyhandler(event: any) {
    this.question.dropdownListModel?.keyHandler(event);
  }
  public blur(event: any) {
    this.question.dropdownListModel?.onBlur(event);
  }
  protected onCreated() {
    if (!this.question.dropdownListModel) {
      this.question.dropdownListModel = new DropdownMultiSelectListModel(this.question);
    }
  }
}

Vue.component("sv-tagbox", TagboxComponent);
export default TagboxComponent;
