var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model.isRunning && _vm.model.showTimerAsClock
    ? _c("div", { class: _vm.model.rootCss }, [
        _vm.model.showProgress
          ? _c(
              "svg",
              {
                class: _vm.model.getProgressCss(),
                style: {
                  strokeDasharray: _vm.circleRadius,
                  strokeDashoffset: _vm.progress,
                },
              },
              [_c("use", { attrs: { "xlink:href": "#icon-timercircle" } })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { class: _vm.model.textContainerCss }, [
          _c("span", { class: _vm.model.majorTextCss }, [
            _vm._v(_vm._s(_vm.model.clockMajorText)),
          ]),
          _vm._v(" "),
          !!_vm.model.clockMinorText
            ? _c("span", { class: _vm.model.minorTextCss }, [
                _vm._v(_vm._s(_vm.model.clockMinorText)),
              ])
            : _vm._e(),
        ]),
      ])
    : _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.model.isRunning && !_vm.model.showTimerAsClock,
              expression: "model.isRunning && !model.showTimerAsClock",
            },
          ],
          class: _vm.model.survey.getCss().timerRoot,
        },
        [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }