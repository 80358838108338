var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.locString.renderAs, {
    tag: "component",
    attrs: { locString: _vm.locString.renderAsData },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }