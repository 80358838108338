var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.locString.hasHtml
    ? _c("span", {
        staticClass: "sv-string-viewer",
        domProps: { innerHTML: _vm._s(_vm.locString.renderedHtml) },
      })
    : _c("span", { staticClass: "sv-string-viewer" }, [
        _vm._v(_vm._s(_vm.locString.renderedHtml)),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }