var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.getCommentAreaCss(true) }, [
    !_vm.question.isReadOnlyRenderDiv()
      ? _c("textarea", {
          class: _vm.question.cssClasses.other || _vm.commentClass,
          style: { resize: _vm.question.resizeStyle },
          attrs: {
            id: _vm.question.otherId,
            readonly: _vm.question.isInputReadOnly,
            disabled: _vm.question.isInputReadOnly,
            maxlength: _vm.question.getOthersMaxLength(),
            placeholder: _vm.question.otherPlaceholder,
            "aria-label": _vm.question.ariaLabel,
            "aria-required": _vm.question.ariaRequired,
          },
          domProps: { value: _vm.question.otherValue },
          on: {
            change: function (e) {
              _vm.question.onOtherValueChange(e)
            },
            input: function (e) {
              _vm.question.onOtherValueInput(e)
            },
          },
        })
      : _vm._e(),
    _vm.question.isReadOnlyRenderDiv()
      ? _c("div", [_vm._v(_vm._s(_vm.question.otherValue))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }