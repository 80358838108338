






import Vue from "vue";
import { Component } from "vue-property-decorator";
import { PaneldynamicAction } from "./paneldynamic-add-btn.vue";

@Component
export class PanelDynamicPrevBtn extends PaneldynamicAction {
   prevPanelClick() {
    this.question.goToPrevPanel();
  }
}
Vue.component("sv-paneldynamic-prev-btn", PanelDynamicPrevBtn);
export default PanelDynamicPrevBtn;
