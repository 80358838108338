









import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { PopupDropdownViewModel } from "survey-core";
import { BaseVue } from "../../base";

@Component
export class PopupPointer extends BaseVue {
  @Prop() model: PopupDropdownViewModel;
}
Vue.component("popup-pointer", PopupPointer);
export default PopupPointer;
