var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.question.cssClasses.tableWrapper,
      style: { overflowX: _vm.question.showHorizontalScroll ? "scroll" : "" },
    },
    [
      _c("table", { class: _vm.question.getTableCss() }, [
        _vm.table.showHeader
          ? _c("thead", [
              _c(
                "tr",
                [
                  _vm._l(_vm.table.headerRow.cells, function (cell) {
                    return [
                      cell.hasTitle
                        ? _c(
                            "th",
                            {
                              key: "header_" + cell.id,
                              class: cell.className,
                              style: {
                                minWidth: cell.minWidth,
                                width: cell.width,
                              },
                            },
                            [
                              _c("survey-string", {
                                attrs: { locString: cell.locTitle },
                              }),
                              _vm._v(" "),
                              !!cell.column
                                ? _c("survey-matrixheaderrequired", {
                                    attrs: {
                                      column: cell.column,
                                      question: _vm.question,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !cell.hasTitle
                        ? _c("td", {
                            key: "header_" + cell.id,
                            class: cell.className,
                            style: {
                              minWidth: cell.minWidth,
                              width: cell.width,
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.table.rows, function (row) {
            return _c(
              "tr",
              {
                key: _vm.question.inputId + "_" + row.id,
                class: row.className,
                attrs: {
                  "data-sv-drop-target-matrix-row": row.row && row.row.id,
                },
              },
              _vm._l(row.cells, function (cell, cellIndex) {
                return _c("survey-matrixcell", {
                  key: row.id + "_" + cellIndex,
                  attrs: { cell: cell, question: _vm.question },
                })
              }),
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.table.showFooter
          ? _c("tfoot", [
              _c(
                "tr",
                _vm._l(_vm.table.footerRow.cells, function (cell, cellIndex) {
                  return _c("survey-matrixcell", {
                    key: "footer_" + cellIndex,
                    attrs: { cell: cell, question: _vm.question },
                  })
                }),
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }