var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question.canAddPanel
    ? _c(
        "button",
        {
          class: _vm.question.getAddButtonCss(),
          attrs: { type: "button" },
          on: { click: _vm.addPanelClick },
        },
        [
          _c("span", { class: _vm.question.cssClasses.buttonAddText }, [
            _vm._v(_vm._s(_vm.question.panelAddText)),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }