var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.rootCheckbox }, [
    _c("div", { class: _vm.question.getCheckboxItemCss() }, [
      _c("label", { class: _vm.question.cssClasses.checkboxLabel }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.question.booleanValue,
              expression: "question.booleanValue",
            },
          ],
          class: _vm.question.cssClasses.controlCheckbox,
          attrs: {
            type: "checkbox",
            name: _vm.question.name,
            id: _vm.question.inputId,
            disabled: _vm.question.isInputReadOnly,
            "aria-required": _vm.question.ariaRequired,
            "aria-label": _vm.question.ariaLabel,
            "aria-invalid": _vm.question.ariaInvalid,
            "aria-describedby": _vm.question.ariaDescribedBy,
          },
          domProps: {
            value: _vm.question.booleanValue,
            indeterminate: _vm.question.isIndeterminate,
            checked: Array.isArray(_vm.question.booleanValue)
              ? _vm._i(_vm.question.booleanValue, _vm.question.booleanValue) >
                -1
              : _vm.question.booleanValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.question.booleanValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.question.booleanValue,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(_vm.question, "booleanValue", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.question,
                      "booleanValue",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.question, "booleanValue", $$c)
              }
            },
          },
        }),
        _c(
          "span",
          { class: _vm.question.cssClasses.checkboxMaterialDecorator },
          [
            _vm.question.svgIcon
              ? _c(
                  "svg",
                  { class: _vm.question.cssClasses.checkboxItemDecorator },
                  [_c("use", { attrs: { "xlink:href": _vm.question.svgIcon } })]
                )
              : _vm._e(),
            _c("span", { staticClass: "check" }),
          ]
        ),
        _vm.question.isLabelRendered
          ? _c(
              "span",
              {
                class: _vm.question.cssClasses.checkboxControlLabel,
                attrs: { id: _vm.question.labelRenderedAriaID },
              },
              [
                !_vm.question.hasTitleActions
                  ? _c("survey-element-title-content", {
                      attrs: { element: _vm.question, css: _vm.css },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.question.hasTitleActions
                  ? _c("sv-title-actions", {
                      attrs: { element: _vm.question, css: _vm.css },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.question.canRenderLabelDescription
        ? _c(
            "div",
            { class: _vm.question.cssDescription },
            [
              _c("survey-string", {
                attrs: { locString: _vm.question.locDescription },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }