var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.renderCssRoot },
    [
      _c("sv-dropdown", { attrs: { question: _vm.question } }),
      _vm._v(" "),
      _vm.question.isOtherSelected
        ? _c("survey-other-choice", { attrs: { question: _vm.question } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }