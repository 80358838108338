var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.surveyWindow.isShowing
    ? _c(
        "div",
        {
          class: _vm.surveyWindow.cssRoot,
          staticStyle: { position: "fixed", bottom: "3px", right: "10px" },
          style: {
            maxWidth: _vm.surveyWindow.renderedWidth,
            width: _vm.surveyWindow.renderedWidth,
          },
        },
        [
          _c(
            "div",
            {
              class: _vm.surveyWindow.cssHeaderRoot,
              on: { click: _vm.doExpand },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "100%",
                    cursor: "pointer",
                    "user-select": "none",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      class: _vm.surveyWindow.cssHeaderTitle,
                      staticStyle: { "padding-right": "10px" },
                    },
                    [
                      _c("survey-string", {
                        attrs: { locString: _vm.windowSurvey.locTitle },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", {
                    class: _vm.expandedCss,
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.isExpandedSurvey
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        float: "right",
                        cursor: "pointer",
                        "user-select": "none",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.surveyWindow.cssHeaderTitle,
                          staticStyle: { "padding-right": "10px" },
                        },
                        [_vm._v("X")]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.isExpandedSurvey
            ? _c(
                "div",
                { class: _vm.surveyWindow.cssBody },
                [
                  _c(_vm.getSurveyComponentName(), {
                    tag: "component",
                    attrs: { survey: _vm.windowSurvey },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }