











































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { MultipleTextItemModel, Question } from "survey-core";
import { QuestionMultipleTextModel } from "survey-core";
import BaseVue from "./base";
import { getComponentName } from "./question";

@Component
export class MultipleTextItem extends BaseVue {
  @Prop()
  question: QuestionMultipleTextModel;
  @Prop()
  item: MultipleTextItemModel

  getModel() {
    return this.item.editor;
  }
  getComponentName(question: Question) {
    return getComponentName(question);
  }
}
Vue.component("survey-multipletext-item", MultipleTextItem);
export default MultipleTextItem;
