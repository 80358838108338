



























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { BaseVue } from "./base";
import { QuestionTextModel } from "survey-core";

@Component
export class QuestionTextInput extends BaseVue {
  @Prop() question: QuestionTextModel;
  get inputStyle(): any {
    return this.question.inputStyle;
  }
  protected getModel() {
      return this.question;
  }
}
Vue.component("survey-text-input", QuestionTextInput);
export default QuestionTextInput;
