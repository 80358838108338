var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { role: "presentation" } }, [
    _c(
      "label",
      {
        class: _vm.getLabelClass(_vm.item),
        attrs: { "aria-label": _vm.question.getAriaItemLabel(_vm.item) },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.question.renderedValue,
              expression: "question.renderedValue",
            },
          ],
          class: _vm.question.cssClasses.itemControl,
          attrs: {
            type: "radio",
            name: _vm.question.questionName,
            id: _vm.question.getItemId(_vm.item),
            "aria-describedby": _vm.question.ariaDescribedBy,
            disabled: !_vm.question.getItemEnabled(_vm.item),
          },
          domProps: {
            value: _vm.item.value,
            checked: _vm._q(_vm.question.renderedValue, _vm.item.value),
          },
          on: {
            change: function ($event) {
              return _vm.$set(_vm.question, "renderedValue", _vm.item.value)
            },
          },
        }),
        _vm.question.cssClasses.materialDecorator
          ? _c("span", { class: _vm.question.cssClasses.materialDecorator }, [
              _vm.question.itemSvgIcon
                ? _c("svg", { class: _vm.question.cssClasses.itemDecorator }, [
                    _c("use", {
                      attrs: { "xlink:href": _vm.question.itemSvgIcon },
                    }),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        !_vm.hideLabel
          ? _c(
              "span",
              { class: _vm.getControlLabelClass(_vm.item) },
              [_c("survey-string", { attrs: { locString: _vm.item.locText } })],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }