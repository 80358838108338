var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model.hasActions
    ? _c(
        "div",
        {
          ref: "container",
          class: _vm.model.getRootCss(),
          on: {
            click: function (event) {
              event.stopPropagation()
            },
          },
        },
        _vm._l(_vm.model.renderedActions, function (item) {
          return _c("sv-action", { key: item.id, attrs: { item: item } })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }