var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.locString.hasHtml
    ? _c("span", {
        staticClass: "sv-string-editor",
        attrs: { contenteditable: "true" },
        domProps: { innerHTML: _vm._s(_vm.locString.renderedHtml) },
        on: { blur: _vm.onInput, click: _vm.onClick },
      })
    : _c(
        "span",
        {
          staticClass: "sv-string-editor",
          attrs: { contenteditable: "true" },
          on: { blur: _vm.onInput, click: _vm.onClick },
        },
        [_vm._v(_vm._s(_vm.locString.renderedHtml))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }