var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.root }, [
    _vm.question.renderedMode === "image"
      ? _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.question.imageLink && !_vm.question.contentNotLoaded,
              expression: "question.imageLink && !question.contentNotLoaded",
            },
          ],
          class: _vm.question.getImageCss(),
          style: { objectFit: _vm.question.imageFit },
          attrs: {
            src: _vm.question.locImageLink.renderedHtml,
            alt: _vm.question.altText || _vm.question.title,
            width: _vm.question.renderedWidth,
            height: _vm.question.renderedHeight,
          },
          on: {
            load: function (event) {
              _vm.question.onLoadHandler()
            },
            error: function (event) {
              _vm.question.onErrorHandler()
            },
          },
        })
      : _vm._e(),
    _vm.question.renderedMode === "video"
      ? _c("video", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.question.imageLink && !_vm.question.contentNotLoaded,
              expression: "question.imageLink && !question.contentNotLoaded",
            },
          ],
          class: _vm.question.getImageCss(),
          style: { objectFit: _vm.question.imageFit },
          attrs: {
            controls: "",
            src: _vm.question.locImageLink.renderedHtml,
            width: _vm.question.renderedWidth,
            height: _vm.question.renderedHeight,
          },
          on: {
            loadedmetadata: function (event) {
              _vm.question.onLoadHandler()
            },
            error: function (event) {
              _vm.question.onErrorHandler()
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.question.renderedMode === "youtube"
      ? _c("iframe", {
          class: _vm.question.getImageCss(),
          style: { objectFit: _vm.question.imageFit },
          attrs: {
            src: _vm.question.locImageLink.renderedHtml,
            width: _vm.question.renderedWidth,
            height: _vm.question.renderedHeight,
          },
        })
      : _vm._e(),
    _vm._v(" "),
    !_vm.question.imageLink || _vm.question.contentNotLoaded
      ? _c(
          "div",
          { class: _vm.question.cssClasses.noImage },
          [
            _c("sv-svg-icon", {
              attrs: {
                iconName: _vm.question.cssClasses.noImageSvgIconId,
                size: 48,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }