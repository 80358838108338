var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.components.length > 0
    ? _c(
        "div",
        { staticClass: "sv-components-column" },
        [
          _vm._l(_vm.components, function (component, index) {
            return [
              _c(component.component, {
                tag: "component",
                attrs: { survey: _vm.survey, model: component.data },
              }),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }