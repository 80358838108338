




















































import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import {
  PopupBaseViewModel,
  createDialogOptions,
  createPopupModalViewModel,
  IDialogOptions,
  settings,
} from "survey-core";
import { BaseVue } from "../../base";

@Component
export class PopupContainer extends BaseVue {
  @Prop() model: PopupBaseViewModel;
  private prevIsVisible: boolean;
  protected getModel() {
    return this.model;
  }
  clickInside(event: any) {
    event.stopPropagation();
  }
  onUpdated() {
    if (!this.prevIsVisible && this.model.isVisible) {
      this.model.updateOnShowing();
    }
    this.prevIsVisible = this.model.isVisible;
  }
}
// replace to showDialog then delete
export function showModal(
  componentName: string,
  data: any,
  onApply: () => boolean,
  onCancel?: () => void,
  cssClass?: string,
  title?: string,
  displayMode: "popup" | "overlay" = "popup"
): PopupBaseViewModel {
  const options = createDialogOptions(
    componentName,
    data,
    onApply,
    onCancel,
    undefined,
    undefined,
    cssClass,
    title,
    displayMode
  );
  return showDialog(options);
}
export function showDialog(dialogOptions: IDialogOptions): PopupBaseViewModel {
  dialogOptions.onHide = () => {
    {
      popup.$destroy();
      popupViewModel.dispose();
    }
  };
  const popupViewModel: PopupBaseViewModel = createPopupModalViewModel(dialogOptions);
  const popup = new PopupContainer({
    el: popupViewModel.container.appendChild(document.createElement("div")),
    propsData: { model: popupViewModel },
  });
  popupViewModel.model.isVisible = true;
  return popupViewModel;
}
settings.showModal = showModal;
Vue.component("sv-popup-container", PopupContainer);
export default PopupContainer;
