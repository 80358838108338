var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.getItemClass(_vm.item) }, [
    _c("label", { class: _vm.question.cssClasses.label }, [
      _vm.question.multiSelect
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.question.value,
                expression: "question.value",
              },
            ],
            class: _vm.question.cssClasses.itemControl,
            attrs: {
              type: "checkbox",
              name: _vm.question.questionName,
              id: _vm.question.getItemId(_vm.item),
              disabled: !_vm.question.getItemEnabled(_vm.item),
              "aria-required": _vm.question.ariaRequired,
              "aria-label": _vm.question.ariaLabel,
              "aria-invalid": _vm.question.ariaInvalid,
              "aria-describedby": _vm.question.ariaDescribedBy,
            },
            domProps: {
              value: _vm.item.value,
              checked: Array.isArray(_vm.question.value)
                ? _vm._i(_vm.question.value, _vm.item.value) > -1
                : _vm.question.value,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.question.value,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.item.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.question, "value", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.question,
                        "value",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.question, "value", $$c)
                }
              },
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.question.value,
                expression: "question.value",
              },
            ],
            class: _vm.question.cssClasses.itemControl,
            attrs: {
              type: "radio",
              name: _vm.question.questionName,
              id: _vm.question.getItemId(_vm.item),
              disabled: !_vm.question.getItemEnabled(_vm.item),
              "aria-required": _vm.question.ariaRequired,
              "aria-label": _vm.question.ariaLabel,
              "aria-invalid": _vm.question.ariaInvalid,
              "aria-describedby": _vm.question.ariaDescribedBy,
            },
            domProps: {
              value: _vm.item.value,
              checked: _vm._q(_vm.question.value, _vm.item.value),
            },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.question, "value", _vm.item.value)
              },
            },
          }),
      _c("div", { class: _vm.question.cssClasses.itemDecorator }, [
        _c("div", { class: _vm.question.cssClasses.imageContainer }, [
          _vm.question.cssClasses.checkedItemDecorator
            ? _c(
                "span",
                { class: this.question.cssClasses.checkedItemDecorator },
                [
                  _vm.question.cssClasses.checkedItemSvgIconId
                    ? _c("sv-svg-icon", {
                        class: _vm.question.cssClasses.checkedItemSvgIcon,
                        attrs: {
                          size: "auto",
                          iconName:
                            _vm.question.cssClasses.checkedItemSvgIconId,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.imageLink &&
          !_vm.item.contentNotLoaded &&
          _vm.question.contentMode === "image"
            ? _c("img", {
                class: _vm.question.cssClasses.image,
                style: { objectFit: _vm.question.imageFit },
                attrs: {
                  src: _vm.item.imageLink,
                  width: _vm.question.renderedImageWidth,
                  height: _vm.question.renderedImageHeight,
                  alt: _vm.item.locText.renderedHtml,
                },
                on: {
                  load: function (event) {
                    _vm.question.onContentLoaded(_vm.item, event)
                  },
                  error: function (event) {
                    _vm.item.onErrorHandler()
                  },
                },
              })
            : _vm._e(),
          _vm.item.imageLink &&
          !_vm.item.contentNotLoaded &&
          _vm.question.contentMode === "video"
            ? _c("video", {
                class: _vm.question.cssClasses.image,
                style: { objectFit: _vm.question.imageFit },
                attrs: {
                  controls: "",
                  src: _vm.item.imageLink,
                  width: _vm.question.renderedImageWidth,
                  height: _vm.question.renderedImageHeight,
                },
                on: {
                  loadedmetadata: function (event) {
                    _vm.question.onContentLoaded(_vm.item, event)
                  },
                  error: function (event) {
                    _vm.item.onErrorHandler()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.item.imageLink || _vm.item.contentNotLoaded
            ? _c(
                "div",
                {
                  class: _vm.question.cssClasses.itemNoImage,
                  style: {
                    width: _vm.question.renderedImageWidth,
                    height: _vm.question.renderedImageHeight,
                    objectFit: _vm.question.imageFit,
                  },
                },
                [
                  _c("sv-svg-icon", {
                    class: _vm.question.cssClasses.itemNoImageSvgIcon,
                    attrs: {
                      iconName: _vm.question.cssClasses.itemNoImageSvgIconId,
                      size: 48,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm.question.showLabel
          ? _c(
              "span",
              { class: _vm.question.cssClasses.itemText },
              [_c("survey-string", { attrs: { locString: _vm.item.locText } })],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }