













































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionImagePickerModel, ImageItemValue } from "survey-core";

@Component
export class ImagePickerItem extends QuestionVue<QuestionImagePickerModel> {
  @Prop() question: QuestionImagePickerModel;
  @Prop() item: ImageItemValue;
  getItemClass(item: any) {
    return this.question.getItemClass(item);
  }
   getModel() {
    return this.item;
  }
}
Vue.component("survey-imagepicker-item", ImagePickerItem);
export default ImagePickerItem;
