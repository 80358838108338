



































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { PanelModel, QuestionPanelDynamicModel } from "survey-core";
import { default as QuestionVue } from "./question";

@Component
export class PanelDynamic extends QuestionVue<QuestionPanelDynamicModel> {
  get renderedPanels(): PanelModel[] {
    if (this.question.isRenderModeList) return this.question.panels;
    const panels = [];
    if (this.question.currentPanel) {
      panels.push(this.question.currentPanel);
    }
    return panels;
  }
  get showLegacyNavigation() {
    return this.question["showLegacyNavigation"];
  }
}
Vue.component("survey-paneldynamic", PanelDynamic);
export default PanelDynamic;
