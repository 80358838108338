


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Question } from "survey-core";

@Component
export class OtherComment extends Vue {
  @Prop() question: Question;
  @Prop() commentClass: any;
}
Vue.component("survey-question-comment", OtherComment);
export default OtherComment;
