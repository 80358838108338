





















import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { Action } from "survey-core";
import { Base } from "survey-core";
import BaseVue from "../../../base";

@Component
export class SurveyQuestionMatrixDetailButton extends BaseVue {
  @Prop() public item: Action;

  get question() {
    return this.item.data.question;
  }

  get row() {
    return this.item.data.row;
  }

  getModel(): Base {
    return this.item;
  }
}

Vue.component("sv-matrix-detail-button", SurveyQuestionMatrixDetailButton);
export default SurveyQuestionMatrixDetailButton;
