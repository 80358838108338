






import Vue from "vue";
import { Component } from "vue-property-decorator";
import { PaneldynamicAction } from "./paneldynamic-add-btn.vue";

@Component
export class PanelDynamicNextBtn extends PaneldynamicAction {
   nextPanelClick() {
    this.question.goToNextPanel();
  }
}
Vue.component("sv-paneldynamic-next-btn", PanelDynamicNextBtn);
export default PanelDynamicNextBtn;
