var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.containerCss },
    [_c("sv-list", { attrs: { model: _vm.listModel } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }