var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: this.cssClass }, [
    _c(
      "div",
      { class: this.cssClass, staticStyle: { clear: "both" } },
      [
        _c(
          "div",
          { class: _vm.question.cssClasses.progressContainer },
          [
            _c("sv-paneldynamic-prev-btn", {
              attrs: { data: { question: _vm.question } },
            }),
            _vm._v(" "),
            _vm.question.isRangeShowing
              ? _c("div", { class: _vm.question.cssClasses.progress }, [
                  _c("div", {
                    class: _vm.question.cssClasses.progressBar,
                    style: { width: _vm.question.progress },
                    attrs: { role: "progressbar" },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("sv-paneldynamic-next-btn", {
              attrs: { data: { question: _vm.question } },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("sv-paneldynamic-add-btn", {
          attrs: { data: { question: _vm.question } },
        }),
        _vm._v(" "),
        _c("sv-paneldynamic-progress-text", {
          attrs: { data: { question: _vm.question } },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }