



































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ItemValue, QuestionRankingModel } from "survey-core"

@Component
export class RankingItem extends Vue {
  @Prop() index: number;
  @Prop() indexText: string;
  @Prop() text: string;
  @Prop() handleKeydown: ()=>{};
  @Prop() cssClasses: any
  @Prop() question: QuestionRankingModel
  @Prop() item: ItemValue
}
Vue.component("survey-ranking-item", RankingItem);
export default RankingItem;
