var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.cssClasses.root },
    [
      _vm.question.getShowNoEntriesPlaceholder()
        ? _c(
            "div",
            { class: _vm.question.cssClasses.noEntriesPlaceholder },
            [
              _c(
                "span",
                [
                  _c("survey-string", {
                    attrs: { locString: _vm.question.locNoEntriesText },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("sv-paneldynamic-add-btn", {
                attrs: { data: { question: _vm.question } },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showLegacyNavigation &&
      _vm.question.isProgressTopShowing &&
      _vm.question.isRangeShowing
        ? _c("div", { class: _vm.question.cssClasses.progress }, [
            _c("div", {
              class: _vm.question.cssClasses.progressBar,
              style: { width: _vm.question.progress },
              attrs: { role: "progressbar" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showLegacyNavigation && _vm.question.isProgressTopShowing
        ? _c("survey-paneldynamicprogress", {
            attrs: { question: _vm.question },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.renderedPanels, function (panel, index) {
        return [
          _c(
            "div",
            { key: panel.id, class: _vm.question.getPanelWrapperCss() },
            [
              _c("survey-panel", { attrs: { question: panel, css: _vm.css } }),
              _vm._v(" "),
              _vm.question.panelRemoveButtonLocation === "right" &&
              _vm.question.canRemovePanel &&
              panel.state !== "collapsed"
                ? _c("sv-paneldynamic-remove-btn", {
                    attrs: { data: { question: _vm.question, panel: panel } },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.question.isRenderModeList &&
          index < _vm.question.visiblePanelCount - 1
            ? _c("hr", {
                key: "separator" + panel.id,
                class: _vm.question.cssClasses.separator,
              })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.showLegacyNavigation && _vm.question.isProgressBottomShowing
        ? _c("survey-paneldynamicprogress", {
            attrs: { question: _vm.question },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLegacyNavigation && _vm.question.isRenderModeList
        ? _c("sv-paneldynamic-add-btn", {
            attrs: { data: { question: _vm.question } },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.showNavigation
        ? _c("survey-paneldynamicprogress-v2", {
            attrs: { question: _vm.question },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }