















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Base, SurveyModel, Question, QuestionRowModel } from "survey-core";
import { getComponentName } from "./question";
import { BaseVue } from "./base";

@Component
export class SurveyElementVue extends BaseVue {
  @Prop() css: any;
  @Prop() survey: SurveyModel;
  @Prop() element: any;
  @Prop() row: QuestionRowModel;
  protected getModel(): Base {
    return this.element;
  }
  getComponentName(element: Question) {
    return getComponentName(element);
  }
  getContentClass(element: Question) {
    return element.cssContent;
  }
  get hasErrorsOnTop() {
    return !this.element.isPanel && (<Question>this.element).showErrorOnTop;
  }
  get hasErrorsOnBottom() {
    return !this.element.isPanel && (<Question>this.element).showErrorOnBottom;
  }
  mounted() {
    if (!this.element.isPanel) {
      (<Question>this.element).afterRender(this.$el as HTMLElement);
    }
  }

}
Vue.component("survey-element", SurveyElementVue);
export default SurveyElementVue;
