var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.rootClass },
    _vm._l(_vm.question.rankingChoices, function (item, index) {
      return _c("survey-ranking-item", {
        key: item.value + "-" + index + "-item",
        class: _vm.question.getItemClass(item),
        attrs: {
          text: item.locText,
          index: index,
          indexText: _vm.getNumberByIndex(index),
          cssClasses: _vm.question.cssClasses,
          question: _vm.question,
          item: item,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }