

















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { LocalizableString } from "survey-core";

@Component
export class SurveyStringEditor extends Vue {
  @Prop() locString: LocalizableString;

  onInput = (event: any) => {
    this.locString.text = event.target.innerText;
  };
  onClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };
}

Vue.component(LocalizableString.editableRenderer, SurveyStringEditor);
export default SurveyStringEditor;
