var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.ratingRootCss }, [
    _c(
      "fieldset",
      { attrs: { role: "radiogroup" } },
      [
        _c("legend", {
          staticClass: "sv-hidden",
          attrs: { role: "presentation" },
        }),
        _vm._v(" "),
        _vm.question.hasMinLabel
          ? _c(
              "span",
              { class: _vm.question.cssClasses.minText },
              [
                _c("survey-string", {
                  attrs: { locString: _vm.question.locMinRateDescription },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.question.renderedRateItems, function (item, index) {
          return _c(_vm.question.itemComponentName, {
            tag: "component",
            attrs: { item: item, index: index, question: _vm.question },
          })
        }),
        _vm._v(" "),
        _vm.question.hasMaxLabel
          ? _c(
              "span",
              { class: _vm.question.cssClasses.maxText },
              [
                _c("survey-string", {
                  attrs: { locString: _vm.question.locMaxRateDescription },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }