




import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionEmptyModel } from "survey-core";

@Component
export class Empty extends QuestionVue<QuestionEmptyModel> {}
Vue.component("survey-empty", Empty);
export default Empty;
