var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.item.getActionRootCss(), attrs: { id: _vm.item.id } },
    [
      _c(
        "div",
        { staticClass: "sv-action__content" },
        [
          _vm.item.needSeparator ? _c("sv-action-bar-separator") : _vm._e(),
          _vm._v(" "),
          _c(_vm.getComponentName(), {
            tag: "component",
            attrs: { item: _vm.item },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }