



















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Boolean } from "./boolean";
import { RendererFactory } from "survey-core";

@Component
export class BooleanRadio extends Boolean {

}

Vue.component("sv-boolean-radio", BooleanRadio);

RendererFactory.Instance.registerRenderer(
  "boolean",
  "radio",
  "sv-boolean-radio"
);

export default BooleanRadio;
