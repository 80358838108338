var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.renderCssRoot },
    [
      _c("div", { class: _vm.question.cssClasses.selectWrapper }, [
        !_vm.question.isReadOnly
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.renderedValue,
                    expression: "question.renderedValue",
                  },
                ],
                class: _vm.question.getControlClass(),
                attrs: {
                  id: _vm.question.inputId,
                  autocomplete: _vm.question.autocomplete,
                  "aria-required": _vm.question.ariaRequired,
                  "aria-label": _vm.question.ariaLabel,
                  "aria-invalid": _vm.question.ariaInvalid,
                  "aria-describedby": _vm.question.ariaDescribedBy,
                  required: _vm.question.isRequired,
                },
                on: {
                  click: _vm.click,
                  keyup: _vm.keyUp,
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.question,
                      "renderedValue",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _vm.question.allowClear
                  ? _c("option", { domProps: { value: undefined } }, [
                      _vm._v(_vm._s(_vm.question.placeholder)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.question.visibleChoices, function (item) {
                  return _c("sv-dropdown-option-item", {
                    key: item.id,
                    attrs: { item: item },
                  })
                }),
              ],
              2
            )
          : _c(
              "div",
              {
                class: _vm.question.getControlClass(),
                attrs: { disabled: "", id: _vm.question.inputId },
              },
              [_vm._v(_vm._s(_vm.question.readOnlyText))]
            ),
      ]),
      _vm._v(" "),
      _vm.question.isOtherSelected
        ? _c("survey-other-choice", { attrs: { question: _vm.question } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }