var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "option",
    {
      attrs: { disabled: !_vm.item.isEnabled },
      domProps: { value: _vm.item.value },
    },
    [_vm._v(_vm._s(_vm.item.text))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }