












import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { QuestionButtonGroupModel } from "survey-core";
import { QuestionVue } from "./question";

@Component
export class ButtonGroup extends QuestionVue<QuestionButtonGroupModel> {
  @Prop() question: QuestionButtonGroupModel;

  getModel(): QuestionButtonGroupModel {
    return this.question;
  }
}
// Vue.component("survey-buttongroup", ButtonGroup);
export default ButtonGroup;
