var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.model.isItemVisible(_vm.item),
          expression: "model.isItemVisible(item)",
        },
        { name: "key2click", rawName: "v-key2click" },
      ],
      key: _vm.item.id,
      class: _vm.model.getItemClass(_vm.item),
      attrs: {
        tabindex: "0",
        role: "option",
        id: _vm.elementId,
        "aria-selected": _vm.model.isItemSelected(_vm.item) ? "true" : "false",
      },
      on: { click: _vm.click },
    },
    [
      _vm.item.needSeparator
        ? _c("div", { class: _vm.model.cssClasses.itemSeparator })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.model.cssClasses.itemBody,
          style: { paddingInlineStart: _vm.model.getItemIndent(_vm.item) },
        },
        [
          _vm.item.iconName && !_vm.item.component
            ? _c("sv-svg-icon", {
                class: _vm.model.cssClasses.itemIcon,
                attrs: { iconName: _vm.item.iconName, size: 24 },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.item.component
            ? _c("survey-string", { attrs: { locString: _vm.item.locTitle } })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.component
            ? _c(_vm.item.component, {
                tag: "component",
                attrs: { item: _vm.item },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }