




















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Question } from "survey-core";

@Component
export class OtherChoice extends Vue {
  @Prop() question: Question;
  @Prop() commentClass: any;
}
Vue.component("survey-other-choice", OtherChoice);
export default OtherChoice;
