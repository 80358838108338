






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionDropdownModel, RendererFactory } from "survey-core";

@Component
export class DropdownSelect extends QuestionVue<QuestionDropdownModel> {
  public click(event: any) {
    this.question.onClick(event);
  }
  public keyUp(event: any) {
    this.question.onKeyUp(event);
  }
}

Vue.component("sv-dropdown-select", DropdownSelect);
RendererFactory.Instance.registerRenderer("dropdown", "select", "sv-dropdown-select");

export default DropdownSelect;
