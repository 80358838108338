










































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DropdownMultiSelectListModel, QuestionTagboxModel } from "survey-core";
import BaseVue from "../../base";

@Component
export class TagboxFilterComponent extends BaseVue {
  @Prop() model: DropdownMultiSelectListModel;
  @Prop() question: QuestionTagboxModel;

  getModel() {
    return this.model;
  }

  inputChange(event: any) {
    this.model.inputStringRendered = event.target.value;
  }
  inputKeyHandler(event: any) {
    this.model.inputKeyHandler(event);
  }
  public blur(event: any) {
    this.model.onBlur(event);
  }
  public focus(event: any) {
    this.model.onFocus(event);
  }
}

Vue.component("sv-tagbox-filter", TagboxFilterComponent);
export default TagboxFilterComponent;
