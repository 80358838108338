var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.cssClasses.selectWrapper },
    [
      !_vm.question.isReadOnly
        ? _c(
            "div",
            {
              class: _vm.question.getControlClass(),
              attrs: {
                id: _vm.question.inputId,
                tabindex: _vm.model.inputReadOnly ? undefined : 0,
                disabled: _vm.question.isInputReadOnly,
                role: _vm.question.ariaRole,
                "aria-required": _vm.question.ariaRequired,
                "aria-label": _vm.question.ariaLabel,
                "aria-invalid": _vm.question.ariaInvalid,
                "aria-describedby": _vm.question.ariaDescribedBy,
                "aria-expanded": _vm.question.ariaExpanded,
                "aria-controls": _vm.model.listElementId,
                "aria-activedescendant": _vm.model.ariaActivedescendant,
                required: _vm.question.isRequired,
              },
              on: { click: _vm.click, keydown: _vm.keyhandler, blur: _vm.blur },
              model: {
                value: _vm.question.renderedValue,
                callback: function ($$v) {
                  _vm.$set(_vm.question, "renderedValue", $$v)
                },
                expression: "question.renderedValue",
              },
            },
            [
              _c(
                "div",
                { class: _vm.question.cssClasses.controlValue },
                [
                  _vm._l(_vm.question.selectedChoices, function (item, index) {
                    return _c("sv-tagbox-item", {
                      key: "item" + index,
                      attrs: { item: item, question: _vm.question },
                    })
                  }),
                  _vm._v(" "),
                  _c("sv-tagbox-filter", {
                    attrs: { model: _vm.model, question: _vm.question },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.question.allowClear &&
              _vm.question.cssClasses.cleanButtonIconId
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.question.isEmpty(),
                          expression: "!question.isEmpty()",
                        },
                      ],
                      class: _vm.question.cssClasses.cleanButton,
                      on: { click: _vm.clear },
                    },
                    [
                      _c("sv-svg-icon", {
                        class: _vm.question.cssClasses.cleanButtonSvg,
                        attrs: {
                          iconName: _vm.question.cssClasses.cleanButtonIconId,
                          title: _vm.question.clearCaption,
                          size: "auto",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.question.isReadOnly
        ? _c("sv-popup", { attrs: { model: _vm.model.popupModel } })
        : _c(
            "div",
            {
              class: _vm.question.getControlClass(),
              attrs: { disabled: "", id: _vm.question.inputId },
            },
            [_c("div", [_vm._v(_vm._s(_vm.question.readOnlyText))])]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }