var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      ref: "svgIcon",
      staticClass: "sv-svg-icon",
      attrs: { role: "img", "aria-label": _vm.title },
    },
    [_c("use")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }