








import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export class BrandInfo extends Vue {
  constructor() {
    super();
  }
}

Vue.component("sv-brand-info", BrandInfo);

export default BrandInfo;
