var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.hint }, [
    _vm.model.showHintPrefix
      ? _c("div", { class: _vm.question.cssClasses.hintPrefix }, [
          _c("span", [_vm._v(_vm._s(_vm.model.hintStringPrefix))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.question.cssClasses.hintSuffixWrapper },
      [
        _vm.question.showSelectedItemLocText
          ? _c("survey-string", {
              attrs: { locString: _vm.question.selectedItemLocText },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.model.showHintString
          ? _c("div", { class: _vm.question.cssClasses.hintSuffix }, [
              _c("span", { staticStyle: { visibility: "hidden" } }, [
                _vm._v(_vm._s(_vm.model.inputStringRendered)),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.model.hintStringSuffix))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.inputStringRendered,
              expression: "model.inputStringRendered",
            },
          ],
          class: _vm.question.cssClasses.filterStringInput,
          attrs: {
            type: "text",
            autocomplete: "off",
            placeholder: _vm.model.filterStringPlaceholder,
            disabled: _vm.question.isInputReadOnly,
            inputmode: _vm.model.inputMode,
            role: _vm.model.filterStringEnabled ? _vm.question.ariaRole : null,
            "aria-label": _vm.question.placeholder,
            "aria-expanded": _vm.question.ariaExpanded,
            "aria-controls": _vm.model.listElementId,
            "aria-activedescendant": _vm.model.ariaActivedescendant,
            id: _vm.question.getInputId(),
            readonly: !_vm.model.searchEnabled ? true : null,
            size: !_vm.model.inputStringRendered ? 1 : null,
          },
          domProps: { value: _vm.model.inputStringRendered },
          on: {
            change: _vm.inputChange,
            keydown: _vm.inputKeyHandler,
            blur: _vm.blur,
            focus: _vm.focus,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.model, "inputStringRendered", $event.target.value)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }