var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    { class: _vm.question.getSelectBaseRootCss() },
    [
      _c("legend", {
        attrs: {
          role: "radio",
          "aria-label": _vm.question.locTitle.renderedHtml,
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.question.visibleChoices, function (item) {
        return !_vm.question.hasColumns
          ? _c("survey-imagepicker-item", {
              key: item.value,
              attrs: { question: _vm.question, item: item },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm._l(_vm.question.columns, function (column, colIndex) {
        return _vm.question.hasColumns
          ? _c(
              "div",
              {
                key: colIndex,
                class: _vm.question.getColumnClass(),
                attrs: { role: "presentation" },
              },
              _vm._l(column, function (item) {
                return _c("survey-imagepicker-item", {
                  key: item.value,
                  attrs: { question: _vm.question, item: item },
                })
              }),
              1
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }