var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.element.cssHeader, on: { click: _vm.clickTitleFunction } },
    [
      _c("survey-element-title", {
        attrs: { element: _vm.element, css: _vm.css },
      }),
      _vm._v(" "),
      _vm.element.hasDescriptionUnderTitle
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.element.isDescriptionVisible,
                  expression: "element.isDescriptionVisible",
                },
              ],
              class: _vm.element.cssDescription,
            },
            [
              _c("survey-string", {
                attrs: { locString: _vm.element.locDescription },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !!_vm.element.additionalTitleToolbar
        ? _c("sv-action-bar", {
            attrs: { model: _vm.element.additionalTitleToolbar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }