var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question.isVisible
    ? _c(
        "div",
        {
          class: _vm.question.getContainerCss(),
          attrs: { id: _vm.question.id },
        },
        [
          _vm.question.showErrorsAbovePanel
            ? _c("survey-errors", { attrs: { element: _vm.question } })
            : _vm._e(),
          _vm._v(" "),
          _vm.question.hasTitle || _vm.question.hasDescription
            ? _c("survey-element-header", {
                attrs: { element: _vm.question, css: _vm.css },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.question.showErrorsAbovePanel
            ? _c("survey-errors", { attrs: { element: _vm.question } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isCollapsed
            ? _c(
                "div",
                {
                  class: _vm.question.cssClasses.panel.content,
                  style: { paddingLeft: _vm.question.innerPaddingLeft },
                  attrs: { id: _vm.question.contentId },
                },
                [
                  _vm._l(_vm.rows, function (row, index) {
                    return [
                      row.visible
                        ? _c("survey-row", {
                            key: _vm.question.id + "_" + index,
                            attrs: {
                              row: row,
                              survey: _vm.survey,
                              css: _vm.css,
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _c("sv-action-bar", {
                    attrs: { model: _vm.question.getFooterToolbar() },
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }