












import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { SurveyModel } from "survey-core";
import { BaseVue } from "../base";

@Component
export class ComponentsContainer extends BaseVue {
  @Prop() survey: SurveyModel;
  @Prop() container: string;
  get components(): Array<any> {
    return this.survey.getContainerContent(this.container as any);
  }
}

Vue.component("sv-components-container", ComponentsContainer);

export default ComponentsContainer;
