var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.question.cssClasses.footer
    ? _c("div", { class: _vm.question.cssClasses.footer }, [
        _c("hr", { class: _vm.question.cssClasses.separator }),
        _vm._v(" "),
        _vm.question.isRangeShowing && _vm.question.isProgressBottomShowing
          ? _c("div", { class: _vm.question.cssClasses.progress }, [
              _c("div", {
                class: _vm.question.cssClasses.progressBar,
                style: { width: _vm.question.progress },
                attrs: { role: "progressbar" },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.question.footerToolbar.visibleActions.length
          ? _c(
              "div",
              { class: _vm.question.cssClasses.footerButtonsContainer },
              [
                _c("sv-action-bar", {
                  attrs: { model: _vm.question.footerToolbar },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }