var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      key: _vm.item.value,
      class: _vm.question.getItemClass(_vm.item.itemValue, _vm.item.highlight),
      style: _vm.question.getItemStyle(_vm.item.itemValue, _vm.item.highlight),
      on: {
        mouseover: function (e) {
          return _vm.question.onItemMouseIn(_vm.item)
        },
        mouseleave: function (e) {
          return _vm.question.onItemMouseOut(_vm.item)
        },
      },
    },
    [
      _c("input", {
        staticClass: "sv-visuallyhidden",
        attrs: {
          type: "radio",
          name: _vm.question.name,
          id: _vm.question.getInputId(_vm.index),
          disabled: _vm.question.isInputReadOnly,
          "aria-required": _vm.question.ariaRequired,
          "aria-label": _vm.question.ariaLabel,
          "aria-invalid": _vm.question.ariaInvalid,
          "aria-describedby": _vm.question.ariaDescribedBy,
        },
        domProps: { value: _vm.item.value },
        on: {
          click: function (e) {
            return _vm.question.setValueFromClick(e.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("sv-svg-icon", {
        attrs: {
          iconName: _vm.question.getItemSmileyIconName(_vm.item.itemValue),
          size: "auto",
          title: _vm.item.text,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }