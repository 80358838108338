var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { role: "presentation" } }, [
    _c(
      "label",
      {
        class: _vm.question.getLabelClass(_vm.item),
        attrs: { "aria-label": _vm.question.getAriaItemLabel(_vm.item) },
      },
      [
        _vm.item == _vm.question.selectAllItem
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isAllSelected,
                  expression: "isAllSelected",
                },
              ],
              class: _vm.question.cssClasses.itemControl,
              attrs: {
                role: "option",
                type: "checkbox",
                name: _vm.question.name,
                id: _vm.question.getItemId(_vm.item),
                disabled: !_vm.question.getItemEnabled(_vm.item),
                "aria-describedby": _vm.question.ariaDescribedBy,
              },
              domProps: {
                value: _vm.isAllSelected,
                checked: Array.isArray(_vm.isAllSelected)
                  ? _vm._i(_vm.isAllSelected, _vm.isAllSelected) > -1
                  : _vm.isAllSelected,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.isAllSelected,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.isAllSelected,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isAllSelected = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isAllSelected = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isAllSelected = $$c
                  }
                },
              },
            })
          : _vm._e(),
        _vm.item != _vm.question.selectAllItem
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.question.renderedValue,
                  expression: "question.renderedValue",
                },
              ],
              class: _vm.question.cssClasses.itemControl,
              attrs: {
                role: "option",
                type: "checkbox",
                name: _vm.question.name,
                id: _vm.question.getItemId(_vm.item),
                disabled: !_vm.question.getItemEnabled(_vm.item),
                "aria-describedby": _vm.question.ariaDescribedBy,
              },
              domProps: {
                value: _vm.item.value,
                checked: Array.isArray(_vm.question.renderedValue)
                  ? _vm._i(_vm.question.renderedValue, _vm.item.value) > -1
                  : _vm.question.renderedValue,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.question.renderedValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.item.value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.question,
                          "renderedValue",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.question,
                          "renderedValue",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.question, "renderedValue", $$c)
                  }
                },
              },
            })
          : _vm._e(),
        _vm.question.cssClasses.materialDecorator
          ? _c("span", { class: _vm.question.cssClasses.materialDecorator }, [
              _vm.question.itemSvgIcon
                ? _c("svg", { class: _vm.question.cssClasses.itemDecorator }, [
                    _c("use", {
                      attrs: { "xlink:href": _vm.question.itemSvgIcon },
                    }),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        !_vm.hideLabel
          ? _c(
              "span",
              { class: _vm.question.cssClasses.controlLabel },
              [_c("survey-string", { attrs: { locString: _vm.item.locText } })],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }