var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass: "sv-popup__pointer",
    style: {
      left: _vm.model.pointerTarget.left,
      top: _vm.model.pointerTarget.top,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }