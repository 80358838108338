var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.model.isDisplayed,
          expression: "model.isDisplayed",
        },
      ],
      class: _vm.model.css,
      style: { visibility: _vm.model.active ? "visible" : "hidden" },
      attrs: { role: "alert", "aria-live": "polite" },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.model.message))]),
      _vm._v(" "),
      _c("sv-action-bar", { attrs: { model: _vm.model.actionBar } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }