(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("survey-core"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define("survey-vue-ui", ["survey-core", "vue"], factory);
	else if(typeof exports === 'object')
		exports["survey-vue-ui"] = factory(require("survey-core"), require("vue"));
	else
		root["SurveyVue"] = factory(root["Survey"], root["Vue"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_survey_core__, __WEBPACK_EXTERNAL_MODULE_vue__) {
return 