var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.question.getPanelRemoveButtonCss(),
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.removePanelClick(_vm.panel)
        },
      },
    },
    [
      _c("span", { class: _vm.question.cssClasses.buttonRemoveText }, [
        _vm._v(_vm._s(_vm.question.panelRemoveText)),
      ]),
      _vm._v(" "),
      _c("span", { class: _vm.question.cssClasses.iconRemove }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }