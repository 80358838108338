
























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionRatingModel } from "survey-core";

@Component
export class Rating extends QuestionVue<QuestionRatingModel> {
  getInputId(index: any) {
    return this.question.getInputId(index)
  }
}
Vue.component("survey-rating", Rating);
export default Rating;
