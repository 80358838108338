














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  Action,
  AdaptiveActionContainer,
  Question,
  PanelModel,
  RendererFactory,
} from "survey-core";

@Component
export class TitleActions extends Vue {
  @Prop() element: Question | PanelModel;
  @Prop() css: any;
  constructor() {
    super();
  }

  public get toolbar(): AdaptiveActionContainer<Action> {
    return this.element.getTitleToolbar();
  }
}

RendererFactory.Instance.registerRenderer("element", "title-actions", "sv-title-actions");

Vue.component("sv-title-actions", TitleActions);
export default TitleActions;
