var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.fileRootCss }, [
    !_vm.question.isReadOnly
      ? _c("input", {
          class: _vm.question.cssClasses.fileInput,
          attrs: {
            tabindex: "-1",
            type: "file",
            id: _vm.question.inputId,
            "aria-required": _vm.question.ariaRequired,
            "aria-label": _vm.question.ariaLabel,
            "aria-invalid": _vm.question.ariaInvalid,
            "aria-describedby": _vm.question.ariaDescribedBy,
            multiple: _vm.question.multipleRendered,
            title: _vm.question.inputTitle,
            accept: _vm.question.acceptedTypes,
          },
          on: { change: _vm.question.doChange },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.question.isReadOnly
      ? _c("input", {
          class: _vm.question.getReadOnlyFileCss(),
          staticStyle: { color: "transparent" },
          attrs: {
            type: "file",
            disabled: "",
            id: _vm.question.inputId,
            multiple: _vm.question.multipleRendered,
            placeholder: _vm.question.title,
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.question.cssClasses.dragArea,
        on: {
          drop: _vm.question.onDrop,
          dragover: _vm.question.onDragOver,
          dragleave: _vm.question.onDragLeave,
          dragenter: _vm.question.onDragEnter,
        },
      },
      [
        _c("div", { class: _vm.question.getFileDecoratorCss() }, [
          _c("span", { class: _vm.question.cssClasses.dragAreaPlaceholder }, [
            _vm._v(_vm._s(_vm.question.dragAreaPlaceholder)),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.question.cssClasses.wrapper }, [
            _c(
              "label",
              {
                directives: [{ name: "key2click", rawName: "v-key2click" }],
                class: _vm.question.getChooseFileCss(),
                attrs: {
                  role: "button",
                  tabindex: "0",
                  for: _vm.question.inputId,
                  "aria-label": _vm.question.chooseButtonCaption,
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.question.chooseButtonCaption))]),
                _vm._v(" "),
                _vm.question.cssClasses.chooseFileIconId
                  ? _c("sv-svg-icon", {
                      attrs: {
                        title: _vm.question.chooseButtonCaption,
                        iconName: _vm.question.cssClasses.chooseFileIconId,
                        size: "auto",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.question.isEmpty()
              ? _c("span", { class: _vm.question.cssClasses.noFileChosen }, [
                  _vm._v(_vm._s(_vm.question.noFileChosenCaption)),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.question.showRemoveButton
          ? _c(
              "button",
              {
                class: _vm.question.cssClasses.removeButton,
                attrs: { type: "button" },
                on: { click: _vm.question.doClean },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.question.clearButtonCaption))]),
                _vm._v(" "),
                _vm.question.cssClasses.removeButtonIconId
                  ? _c("sv-svg-icon", {
                      attrs: {
                        iconName: _vm.question.cssClasses.removeButtonIconId,
                        size: "auto",
                        title: _vm.question.clearButtonCaption,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.question.isEmpty()
          ? _c(
              "div",
              { class: _vm.question.cssClasses.fileList || undefined },
              _vm._l(_vm.question.previewValue, function (val, index) {
                return _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: val && _vm.isPreviewVisible(index),
                        expression: "val && isPreviewVisible(index)",
                      },
                    ],
                    key: _vm.question.inputId + "_" + index,
                    class: _vm.question.cssClasses.preview,
                  },
                  [
                    val.name && _vm.question.cssClasses.fileSign
                      ? _c("div", { class: _vm.question.cssClasses.fileSign }, [
                          _c(
                            "a",
                            {
                              style: { width: _vm.question.imageWidth },
                              attrs: {
                                href: val.content,
                                title: val.name,
                                download: val.name,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.question.doDownloadFile(
                                    $event,
                                    val
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(val.name))]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.question.cssClasses.imageWrapper },
                      [
                        _vm.question.canPreviewImage(val)
                          ? _c("img", {
                              style: {
                                height: _vm.question.imageHeight,
                                width: _vm.question.imageWidth,
                              },
                              attrs: { src: val.content, alt: "File preview" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.question.defaultImage(val)
                          ? _c("sv-svg-icon", {
                              class: _vm.question.cssClasses.defaultImage,
                              attrs: {
                                iconName:
                                  _vm.question.cssClasses.defaultImageIconId,
                                size: "auto",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        val.name && !_vm.question.isReadOnly
                          ? _c(
                              "div",
                              {
                                class: _vm.question.cssClasses.removeFileButton,
                                on: {
                                  click: function ($event) {
                                    return _vm.question.doRemoveFile(val)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { class: _vm.question.cssClasses.removeFile },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.question.removeFileCaption)
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.question.cssClasses.removeFileSvgIconId
                                  ? _c("sv-svg-icon", {
                                      class:
                                        _vm.question.cssClasses.removeFileSvg,
                                      attrs: {
                                        title: _vm.question.removeFileCaption,
                                        iconName:
                                          _vm.question.cssClasses
                                            .removeFileSvgIconId,
                                        size: "auto",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    val.name && _vm.question.cssClasses.fileSignBottom
                      ? _c(
                          "div",
                          { class: _vm.question.cssClasses.fileSignBottom },
                          [
                            _c(
                              "a",
                              {
                                style: { width: _vm.question.imageWidth },
                                attrs: {
                                  href: val.content,
                                  title: val.name,
                                  download: val.name,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.question.doDownloadFile(
                                      $event,
                                      val
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(val.name))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.question.showRemoveButtonBottom
          ? _c(
              "button",
              {
                class: _vm.question.showRemoveButtonBottom,
                attrs: { type: "button" },
                on: { click: _vm.question.doClean },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.question.clearButtonCaption))]),
                _vm._v(" "),
                _vm.question.cssClasses.removeButtonIconId
                  ? _c("sv-svg-icon", {
                      attrs: {
                        iconName: _vm.question.cssClasses.removeButtonIconId,
                        size: "auto",
                        title: _vm.question.clearButtonCaption,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.question.mobileFileNavigatorVisible
          ? _c("sv-action-bar", {
              attrs: { model: _vm.question.mobileFileNavigator },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }