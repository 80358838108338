var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { class: _vm.question.getItemLabelCss(_vm.item) },
    [
      _c(
        "span",
        { class: _vm.question.getItemTitleCss() },
        [
          _vm.item.editor.isRequireTextBeforeTitle ||
          _vm.item.editor.isRequireTextOnStart
            ? _c("span", { class: _vm.question.cssClasses.requiredText }, [
                _vm._v(_vm._s(_vm.item.editor.requiredText)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("survey-string", { attrs: { locString: _vm.item.locTitle } }),
          _vm._v(" "),
          _vm.item.editor.isRequireTextAfterTitle
            ? _c("span", { class: _vm.question.cssClasses.requiredText }, [
                _vm._v(_vm._s(_vm.item.editor.requiredText)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { key: _vm.item.editor.id, class: _vm.question.getItemCss() },
        [
          _vm.item.editor.showErrorOnTop
            ? _c("survey-errors", {
                attrs: { element: _vm.item.editor, location: "top" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(_vm.getComponentName(_vm.item.editor), {
            tag: "component",
            attrs: { question: _vm.item.editor },
          }),
          _vm._v(" "),
          _vm.item.editor.showErrorOnBottom
            ? _c("survey-errors", {
                attrs: { element: _vm.item.editor, location: "bottom" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.editor.isErrorsModeTooltip
        ? _c("survey-errors", {
            attrs: { element: _vm.item.editor, location: "tooltip" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }