



















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Base, SurveyTimerModel } from "survey-core";
import { BaseVue } from "./base";

@Component
export class TimerPanel extends BaseVue {
  @Prop() model: SurveyTimerModel;
  @Prop() css: any;
  public readonly circleRadius: number = 440;
  public get progress(): number {
    return -this.circleRadius * this.model.progress;
  }
  get text() {
    return this.model.text;
  }
  protected getModel(): Base {
    return this.model;
  }
}
Vue.component("sv-timerpanel", TimerPanel);
export default TimerPanel;
