
















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { default as QuestionVue } from "../question";
import { QuestionRankingModel } from "survey-core";

@Component
export class Ranking extends QuestionVue<QuestionRankingModel> {
  getNumberByIndex(index: any) {
    return this.question.getNumberByIndex(index)
  }
}

Vue.component("survey-ranking", Ranking);
export default Ranking;
