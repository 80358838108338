



























































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Question, DropdownListModel, Helpers } from "survey-core";
import BaseVue from "../../base";

@Component
export class DropdownComponent extends BaseVue {
  @Prop() question: Question;
  inputElement: any;

  get model() {
    return this.question.dropdownListModel;
  }
  getModel() {
    if (!this.question.dropdownListModel) {
      this.question.dropdownListModel = new DropdownListModel(this.question);
    }
    return this.model;
  }

  inputChange(event: any) {
    this.model.inputStringRendered = event.target.value;
  }

  public click(event: any) {
    this.model?.onClick(event);
  }
  public clear(event: any) {
    this.model?.onClear(event);
  }
  public keyhandler(event: any) {
    this.model?.keyHandler(event);
  }
  public blur(event: any) {
    this.model?.onBlur(event);
    this.updateInputDomElement();
  }
  public focus(event: any) {
    this.model?.onFocus(event);
  }

  protected onMounted() {
    this.inputElement = this.$refs["inputElement"];
    this.updateInputDomElement();
  }
  protected onUpdated() {
    this.updateInputDomElement();
  }
  updateInputDomElement() {
    if (!!this.inputElement) {
      const control: any = this.inputElement;
      const newValue = this.model.inputStringRendered;
      if (!Helpers.isTwoValueEquals(newValue, control.value)) {
        control.value = this.model.inputStringRendered;
      }
    }
  }
}

Vue.component("sv-dropdown", DropdownComponent);
export default DropdownComponent;
