var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item.visible
    ? _c("input", {
        class: _vm.item.innerCss,
        attrs: {
          type: "button",
          disabled: _vm.item.disabled,
          value: _vm.item.title,
          title: _vm.item.getTooltip(),
        },
        on: { mousedown: _vm.buttonMouseDown, click: _vm.item.action },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }