var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.element.hasVisibleErrors
    ? _c(
        "div",
        {
          class: _vm.element.cssError,
          attrs: {
            role: "alert",
            "aria-live": "polite",
            id: _vm.element.id + "_errors",
          },
        },
        _vm._l(_vm.element.errors, function (error, index) {
          return _c("div", { key: "error_" + index }, [
            _c("span", {
              class: _vm.element.cssClasses
                ? _vm.element.cssClasses.error.icon || undefined
                : "panel-error-icon",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                class: _vm.element.cssClasses
                  ? _vm.element.cssClasses.error.item || undefined
                  : "panel-error-item",
              },
              [_c("survey-string", { attrs: { locString: error.locText } })],
              1
            ),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }