var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      class: _vm.model.css.label,
      attrs: { title: _vm.model.caption.renderedHtml },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model.question.renderedValue,
            expression: "model.question.renderedValue",
          },
        ],
        class: _vm.model.css.control,
        attrs: {
          type: "radio",
          name: _vm.model.name,
          id: _vm.model.id,
          role: "radio",
          "aria-required": _vm.model.isRequired,
          "aria-label": _vm.model.caption.renderedHtml,
          "aria-invalid": _vm.model.hasErrors,
          "aria-describedby": _vm.model.describedBy,
          disabled: _vm.model.readOnly,
        },
        domProps: {
          value: _vm.model.value,
          checked: _vm._q(_vm.model.question.renderedValue, _vm.model.value),
        },
        on: {
          change: function ($event) {
            return _vm.$set(
              _vm.model.question,
              "renderedValue",
              _vm.model.value
            )
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.model.css.decorator },
        [
          !!_vm.model.iconName
            ? _c("sv-svg-icon", {
                class: _vm.model.css.icon,
                attrs: {
                  iconName: _vm.model.iconName,
                  size: _vm.model.iconSize,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.model.showCaption
            ? _c(
                "span",
                { class: _vm.model.css.caption },
                [
                  _c("survey-string", {
                    attrs: { locString: _vm.model.caption },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }