

























import Vue from "vue";
import { Component } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionImagePickerModel } from "survey-core";

@Component
export class ImagePicker extends QuestionVue<QuestionImagePickerModel> {
  getItemClass(item: any) {
    return this.question.getItemClass(item);
  }
}
Vue.component("survey-imagepicker", ImagePicker);
export default ImagePicker;
