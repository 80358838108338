var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.element.hasTitle
    ? _c(
        _vm.element.titleTagName,
        {
          tag: "component",
          class: _vm.element.cssTitle,
          attrs: {
            "aria-label": _vm.element.titleAriaLabel,
            id: _vm.element.ariaTitleId,
            tabindex: _vm.element.titleTabIndex,
            "aria-expanded": _vm.element.titleAriaExpanded,
            role: _vm.element.titleAriaRole,
          },
          on: {
            keyup: function ($event) {
              _vm.keyup($event)
            },
          },
        },
        [
          !_vm.element.hasTitleActions
            ? _c("survey-element-title-content", {
                attrs: { element: _vm.element, css: _vm.css },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.element.hasTitleActions
            ? _c("sv-title-actions", {
                attrs: { element: _vm.element, css: _vm.css },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }