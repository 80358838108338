var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.cssClasses.root, attrs: { role: "group" } },
    [
      _vm._l(_vm.question.visibleChoices, function (item, index) {
        return [
          _c("sv-button-group-item", {
            key: _vm.question.inputId + "_" + index,
            attrs: { item: item, question: _vm.question, index: index },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }