var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    {
      class: _vm.question.getSelectBaseRootCss(),
      attrs: { role: "presentation" },
    },
    [
      _vm._l(_vm.question.visibleChoices, function (item, index) {
        return !_vm.question.hasColumns && !_vm.question.blockedRow
          ? _c("survey-radiogroup-item", {
              key: item.value,
              class: _vm.getItemClass(item),
              attrs: { question: _vm.question, item: item, index: index },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.question.blockedRow
        ? _c(
            "div",
            { class: _vm.question.cssClasses.rootRow },
            _vm._l(_vm.question.dataChoices, function (item, index) {
              return !_vm.question.hasColumns && _vm.question.blockedRow
                ? _c("survey-radiogroup-item", {
                    key: item.value,
                    class: _vm.question.getItemClass(item),
                    attrs: { question: _vm.question, item: item, index: index },
                  })
                : _vm._e()
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.question.hasColumns
        ? _c(
            "div",
            { class: _vm.question.cssClasses.rootMultiColumn },
            _vm._l(_vm.question.columns, function (column, colIndex) {
              return _vm.question.hasColumns
                ? _c(
                    "div",
                    {
                      class: _vm.question.getColumnClass(),
                      attrs: { role: "presentation" },
                    },
                    _vm._l(column, function (item, index) {
                      return _c("survey-radiogroup-item", {
                        key: item.value,
                        class: _vm.getItemClass(item),
                        attrs: {
                          question: _vm.question,
                          item: item,
                          index: "" + colIndex + index,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e()
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.question.footItems, function (item, index) {
        return _vm.question.hasFootItems
          ? _c("survey-radiogroup-item", {
              key: item.value,
              class: _vm.question.getItemClass(item),
              attrs: { question: _vm.question, item: item, index: "" + index },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.question.renderedValue && _vm.question.isOtherSelected
        ? _c("survey-other-choice", { attrs: { question: _vm.question } })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.showClearButtonInContent
        ? _c("div", [
            _c("input", {
              class: _vm.question.cssClasses.clearButton,
              attrs: { type: "button", value: _vm.question.clearButtonCaption },
              on: {
                click: function () {
                  _vm.question.clearValue()
                },
              },
            }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }