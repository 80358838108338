



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Base, PopupSurveyModel, SurveyModel } from "survey-core";
import { BaseVue } from "./base";

@Component
export class PopupSurvey extends BaseVue {
  @Prop() window: PopupSurveyModel;
  @Prop() survey: SurveyModel;
  @Prop() isExpanded: boolean;
  @Prop() isexpanded: boolean;
  @Prop() closeOnCompleteTimeout: number;

  surveyWindow: PopupSurveyModel;
  constructor() {
    super();
    if (this.window) {
      this.surveyWindow = this.window;
    } else {
      this.surveyWindow = new PopupSurveyModel(null, this.survey);
    }
    if (this.isexpanded !== undefined) {
      this.surveyWindow.isExpanded = this.isexpanded;
    }
    if (this.isExpanded !== undefined) {
      this.surveyWindow.isExpanded = this.isExpanded;
    }
    if (this.closeOnCompleteTimeout !== undefined) {
      this.surveyWindow.closeOnCompleteTimeout = this.closeOnCompleteTimeout;
    }
    this.surveyWindow.isShowing = true;
  }
  protected getModel(): Base {
    return this.surveyWindow;
  }

  get windowSurvey(): SurveyModel {
    return this.surveyWindow.survey;
  }
  get css() {
    return !!this.survey ? this.survey.getCss() : {};
  }
  get expandedCss() {
    return this.surveyWindow.isExpanded
      ? this.css.window.header.buttonCollapsed
      : this.css.window.header.buttonExpanded;
  }
  get isExpandedSurvey(): boolean {
    return this.surveyWindow.isExpanded;
  }
  set isExpandedSurvey(val: boolean) {
    this.surveyWindow.isExpanded = val;
  }
  doExpand() {
    this.surveyWindow.changeExpandCollapse();
  }
  getSurveyComponentName() {
    return "survey";
  }
}

/**
 * Obsolete, please use PopupSurvey
 */
export class SurveyWindow extends PopupSurvey {}
Vue.component("survey-window", SurveyWindow);

Vue.component("popup-survey", PopupSurvey);
export default PopupSurvey;
