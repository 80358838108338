var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question.isVisible
    ? _c(
        "div",
        {
          class: _vm.question.cssClasses.panel.container,
          style: _vm.rootStyle,
        },
        [
          _c(
            "h4",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasTitle,
                  expression: "hasTitle",
                },
              ],
              class: _vm.question.cssTitle,
              on: { click: _vm.changeExpanded },
            },
            [
              _c("survey-string", {
                attrs: { locString: _vm.question.locTitle },
              }),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showIcon,
                    expression: "showIcon",
                  },
                ],
                class: _vm.iconCss,
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.question.cssClasses.panel.description },
            [
              _c("survey-string", {
                attrs: { locString: _vm.question.locDescription },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("survey-errors", { attrs: { element: _vm.question } }),
          _vm._v(" "),
          _c(
            "f-panel",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isCollapsed,
                  expression: "!isCollapsed",
                },
              ],
              style: { paddingLeft: _vm.question.innerPaddingLeft },
            },
            [
              _c("survey-flowpanelelement", {
                attrs: { node: _vm.rootNode, panel: _vm.question, css: "css" },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }