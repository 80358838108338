































import { ItemValue, Base } from "survey-core";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { BaseVue } from "./base";

@Component
export class BooleanRadioItem extends Vue {
  @Prop() question: any;
  @Prop() locText: any;
  @Prop() value: any;

  handleChange = (event: any) => {
    this.question.booleanValue = event.target.value == "true";
  }
}
Vue.component("sv-boolean-radio-item", BooleanRadioItem);
export default BooleanRadioItem;
