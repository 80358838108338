var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { class: _vm.question.cssClasses.root }, [
    _c(
      "tbody",
      _vm._l(_vm.question.getRows(), function (row, rowindex) {
        return _c(
          "tr",
          {
            key: _vm.question.inputId + "rowkey" + rowindex,
            class: _vm.question.cssClasses.row,
          },
          [
            _vm._l(row, function (item) {
              return [
                _c(
                  "td",
                  {
                    key: "item" + item.editor.id,
                    class: _vm.question.cssClasses.cell,
                  },
                  [
                    _c("survey-multipletext-item", {
                      attrs: { question: _vm.question, item: item },
                    }),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }