var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.column.isRenderedRequired
    ? _c("span", { class: _vm.question.cssClasses.cellRequiredText }, [
        _vm._v(_vm._s(_vm.column.requiredText)),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }