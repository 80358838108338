var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.survey.getProgressCssClasses() }, [
    _c(
      "div",
      {
        class: _vm.survey.css.progressBar,
        style: { width: _vm.progress },
        attrs: {
          role: "progressbar",
          "aria-valuemin": "0",
          "aria-valuemax": "100",
        },
      },
      [
        _c("span", { class: _vm.getProgressTextInBarCss(_vm.survey.css) }, [
          _vm._v(_vm._s(_vm.survey.progressText)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("span", { class: _vm.getProgressTextUnderBarCss(_vm.survey.css) }, [
      _vm._v(_vm._s(_vm.survey.progressText)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }