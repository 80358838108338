var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.survey.getRootCss(),
      style: {
        backgroundImage: _vm.vueSurvey.renderBackgroundImage,
        backgroundSize: _vm.vueSurvey.backgroundImageFit,
      },
    },
    [
      _c(
        "form",
        {
          style: { backgroundColor: _vm.vueSurvey.renderBackgroundOpacity },
          attrs: { onsubmit: "return false;" },
        },
        [
          !_vm.vueSurvey.hasLogo
            ? _c("div", { staticClass: "sv_custom_header" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.css.container },
            [
              _c("survey-header", { attrs: { survey: _vm.vueSurvey } }),
              _vm._v(" "),
              _c("sv-components-container", {
                tag: "component",
                attrs: { survey: _vm.vueSurvey, container: "header" },
              }),
              _vm._v(" "),
              _vm.vueSurvey.isShowingPage
                ? [
                    _c(
                      "div",
                      { class: _vm.vueSurvey.bodyContainerCss },
                      [
                        _c("sv-components-container", {
                          tag: "component",
                          attrs: { survey: _vm.vueSurvey, container: "left" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: _vm.vueSurvey.bodyCss,
                            style: { maxWidth: _vm.survey.renderedWidth },
                            attrs: { id: _vm.pageId },
                          },
                          [
                            _c("sv-components-container", {
                              tag: "component",
                              attrs: {
                                survey: _vm.vueSurvey,
                                container: "contentTop",
                              },
                            }),
                            _vm._v(" "),
                            _c("survey-page", {
                              key: _vm.pageKey,
                              attrs: {
                                survey: _vm.vueSurvey,
                                page: _vm.vueSurvey.activePage,
                                css: _vm.css,
                              },
                            }),
                            _vm._v(" "),
                            _c("sv-components-container", {
                              tag: "component",
                              attrs: {
                                survey: _vm.vueSurvey,
                                container: "contentBottom",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("sv-components-container", {
                          tag: "component",
                          attrs: { survey: _vm.vueSurvey, container: "right" },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("sv-components-container", {
                tag: "component",
                attrs: { survey: _vm.vueSurvey, container: "footer" },
              }),
              _vm._v(" "),
              _vm.hasCompletedPage
                ? _c("div", [
                    _c("div", {
                      class: _vm.vueSurvey.completedCss,
                      domProps: {
                        innerHTML: _vm._s(_vm.getProcessedCompletedHtml()),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.vueSurvey.state === "completedbefore"
                ? _c("div", {
                    class: _vm.css.body,
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.vueSurvey.processedCompletedBeforeHtml
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.vueSurvey.state === "loading"
                ? _c("div", {
                    class: _vm.css.body,
                    domProps: {
                      innerHTML: _vm._s(_vm.vueSurvey.processedLoadingHtml),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.vueSurvey.state === "empty"
                ? _c("div", { class: _vm.css.bodyEmpty }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.vueSurvey.emptySurveyText) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.vueSurvey.showBrandInfo ? _c("sv-brand-info") : _vm._e(),
      _vm._v(" "),
      _c("sv-notifier", { attrs: { model: _vm.vueSurvey.notifier } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }