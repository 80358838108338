var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question.isReadOnlyRenderDiv()
    ? _c("div", [_vm._v(_vm._s(_vm.question.value))])
    : _vm.question.dataListId
    ? _c(
        "div",
        [
          _c("survey-text-input", { attrs: { question: _vm.question } }),
          _vm._v(" "),
          _c(
            "datalist",
            { attrs: { id: _vm.question.dataListId } },
            _vm._l(_vm.question.dataList, function (item) {
              return _c("option", { domProps: { value: item } })
            }),
            0
          ),
        ],
        1
      )
    : _c("survey-text-input", { attrs: { question: _vm.question } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }