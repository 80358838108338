var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.css.progressButtonsContainerCenter }, [
    _c("div", { class: _vm.css.progressButtonsContainer }, [
      _c("div", {
        class: _vm.getScrollButtonCss(_vm.hasScroller, true),
        attrs: { role: "button" },
        on: {
          click: function ($event) {
            return _vm.clickScrollButton(true)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "progressButtonsListContainer",
          class: _vm.css.progressButtonsListContainer,
        },
        [
          _c(
            "ul",
            { class: _vm.css.progressButtonsList },
            _vm._l(_vm.survey.visiblePages, function (page, index) {
              return _c(
                "li",
                {
                  key: "listelement" + index,
                  class: _vm.getListElementCss(index),
                  on: {
                    click: function ($event) {
                      _vm.isListElementClickable(index)
                        ? _vm.clickListElement(index)
                        : null
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.css.progressButtonsPageTitle,
                      attrs: {
                        title:
                          page.locNavigationTitle.renderedHtml || page.name,
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            page.locNavigationTitle.renderedHtml || page.name
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.css.progressButtonsPageDescription,
                      attrs: {
                        title: page.locNavigationDescription.renderedHtml,
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(page.locNavigationDescription.renderedHtml) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        class: _vm.getScrollButtonCss(_vm.hasScroller, false),
        attrs: { role: "button" },
        on: {
          click: function ($event) {
            return _vm.clickScrollButton(false)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }