var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.item.key, staticClass: "sv-tagbox__item" }, [
    _c(
      "div",
      { staticClass: "sv-tagbox__item-text" },
      [_c("survey-string", { attrs: { locString: _vm.item.locText } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.question.cssClasses.cleanItemButton,
        on: { click: _vm.removeItem },
      },
      [
        _c("sv-svg-icon", {
          class: _vm.question.cssClasses.cleanItemButtonSvg,
          attrs: {
            iconName: _vm.question.cssClasses.cleanItemButtonIconId,
            size: "auto",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }