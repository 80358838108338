













































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SurveyModel, SurveyProgressButtonsModel } from "survey-core";

@Component
export class ProgressButtons extends Vue {
  @Prop() survey: SurveyModel;
  public hasScroller: boolean = false;
  private progressButtonsModel: SurveyProgressButtonsModel;
  private updateScroller: any = undefined;
  constructor() {
    super();
    this.progressButtonsModel = new SurveyProgressButtonsModel(this.survey);
  }
  public get css() {
    return this.survey.css;
  }
  mounted() {
    const listContainerElement: any = this.$refs["progressButtonsListContainer"];
    this.updateScroller = setInterval(() => {
      this.hasScroller =
        listContainerElement.scrollWidth > listContainerElement.offsetWidth;
    }, 100);
  }
  public isListElementClickable(index: any): boolean {
    return this.progressButtonsModel.isListElementClickable(index);
  }
  public getListElementCss(index: any): string {
    return this.progressButtonsModel.getListElementCss(index);
  }
  public clickListElement(index: any): void {
    this.progressButtonsModel.clickListElement(index);
  }
  public getScrollButtonCss(hasScroller: boolean, isLeftScroll: boolean): any {
    return this.progressButtonsModel.getScrollButtonCss(hasScroller, isLeftScroll);
  }
  public clickScrollButton(isLeftScroll: boolean): void {
    let listContainerElement: any = this.$refs["progressButtonsListContainer"];
    listContainerElement.scrollLeft += (isLeftScroll ? -1 : 1) * 70;
  }
  beforeDestroy() {
    if (typeof this.updateScroller !== "undefined") {
      clearInterval(this.updateScroller);
      this.updateScroller = undefined;
    }
  }
}

Vue.component("sv-progress-buttons", ProgressButtons);
export default ProgressButtons;
