



































import { Component, Prop } from "vue-property-decorator";

import Vue from "vue";
import { SurveyElementCore } from "survey-core";
@Component
export class TitleContent extends Vue {
  @Prop() element: any;
  @Prop() css: any;

}
Vue.component("survey-element-title-content", TitleContent);
export default TitleContent;
