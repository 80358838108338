var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.question.cssClasses.rootRadio }, [
    _c(
      "fieldset",
      {
        class: _vm.question.cssClasses.radioFieldset,
        attrs: { role: "presentation" },
      },
      [
        _c("sv-boolean-radio-item", {
          key: false,
          attrs: {
            question: _vm.question,
            locText: _vm.question.locLabelFalse,
            value: false,
          },
        }),
        _vm._v(" "),
        _c("sv-boolean-radio-item", {
          key: true,
          attrs: {
            question: _vm.question,
            locText: _vm.question.locLabelTrue,
            value: true,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }