var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    {
      class: _vm.cell.className,
      style: _vm.getCellStyle(),
      attrs: {
        "data-responsive-title": _vm.getHeaders(),
        title: _vm.cell.getTitle(),
        colspan: _vm.cell.colSpans,
      },
    },
    [
      _vm.cell.isActionsCell
        ? _c("sv-action-bar", {
            attrs: { model: _vm.cell.item.getData(), handleClick: false },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.cell.hasPanel
        ? _c(_vm.getComponentName(_vm.cell.panel), {
            tag: "component",
            attrs: { question: _vm.cell.panel, css: _vm.question.cssClasses },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.cell.hasQuestion
        ? _c(
            "div",
            { class: _vm.question.cssClasses.cellQuestionWrapper },
            [
              _vm.cell.showErrorOnTop
                ? _c("survey-errors", {
                    attrs: { element: _vm.cell.question, location: "top" },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.cell.isChoice && _vm.cell.question.isDefaultRendering()
                ? _c(_vm.getComponentName(_vm.cell.question), {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isVisible,
                        expression: "isVisible",
                      },
                    ],
                    tag: "component",
                    attrs: { question: _vm.cell.question },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.cell.isChoice && !_vm.cell.question.isDefaultRendering()
                ? _c(_vm.cell.question.getComponentName(), {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isVisible,
                        expression: "isVisible",
                      },
                    ],
                    tag: "component",
                    attrs: { question: _vm.cell.question },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cell.isRadio
                ? _c("survey-radiogroup-item", {
                    key: _vm.cell.item.value,
                    class: _vm.cell.question.getItemClass(_vm.cell.item),
                    attrs: {
                      question: _vm.cell.question,
                      item: _vm.cell.item,
                      index: _vm.getCellIndex(),
                      hideLabel: true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cell.isCheckbox
                ? _c("survey-checkbox-item", {
                    key: _vm.cell.item.value,
                    class: _vm.cell.question.getItemClass(_vm.cell.item),
                    attrs: {
                      question: _vm.cell.question,
                      item: _vm.cell.item,
                      index: _vm.getCellIndex(),
                      hideLabel: true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cell.isOtherChoice
                ? _c("survey-other-choice", {
                    attrs: { question: _vm.cell.question },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cell.showErrorOnBottom
                ? _c("survey-errors", {
                    attrs: { element: _vm.cell.question, location: "bottom" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cell.question.isErrorsModeTooltip
                ? _c("survey-errors", {
                    attrs: { element: _vm.cell.question, location: "tooltip" },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.cell.hasTitle
        ? _c("survey-string", { attrs: { locString: _vm.cell.locTitle } })
        : _vm._e(),
      _vm._v(" "),
      !!_vm.cell.requiredText
        ? _c("span", { class: _vm.question.cssClasses.cellRequiredText }, [
            _vm._v(_vm._s(_vm.cell.requiredText)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }