







































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SurveyModel } from "survey-core";


@Component
export class SurveyHeader extends Vue {
  @Prop() survey: SurveyModel;

  mounted() {
    var el = this.$el;
    if (el && this.survey) this.survey.afterRenderHeader(<any>el);
  }
}

Vue.component("survey-header", SurveyHeader);
export default SurveyHeader;
