





































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionRadiogroupModel } from "survey-core";

@Component
export class Radiogroup extends QuestionVue<QuestionRadiogroupModel> {
  get choicesCount() {
    return this.question.visibleChoices.length - 1;
  }
  getItemClass(item: any) {
    return this.question.getItemClass(item);
  }
}
Vue.component("survey-radiogroup", Radiogroup);
export default Radiogroup;
