






























import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import {
  ButtonGroupItemModel,
  ItemValue,
  QuestionButtonGroupModel,
} from "survey-core";
import { BaseVue } from "./base";
@Component
export class ButtonGroupItem extends BaseVue {
  @Prop() item: ItemValue;
  @Prop() question: QuestionButtonGroupModel;
  @Prop() index: number;
  public model: ButtonGroupItemModel;

  constructor(props: any) {
    super(props);
    this.model = new ButtonGroupItemModel(this.question, this.item, this.index);
  }
  getModel(): ItemValue {
    return this.item;
  }
}

Vue.component("sv-button-group-item", ButtonGroupItem);
export default ButtonGroupItem;
