var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "listContainerElement", class: _vm.model.cssClasses.root },
    [
      _vm.model.showFilter
        ? _c("div", { class: _vm.model.cssClasses.filter }, [
            _c(
              "div",
              { class: _vm.model.cssClasses.filterIcon },
              [
                _c("sv-svg-icon", {
                  attrs: { iconName: "icon-search", size: "auto" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              class: _vm.model.cssClasses.filterInput,
              attrs: {
                type: "text",
                "aria-label": _vm.model.filterStringPlaceholder,
                placeholder: _vm.model.filterStringPlaceholder,
              },
              domProps: { value: _vm.model.filterString },
              on: { change: _vm.change, keyup: _vm.keyup },
            }),
            _vm._v(" "),
            _vm.model.showSearchClearButton && !!_vm.model.filterString
              ? _c(
                  "button",
                  {
                    class: _vm.model.cssClasses.searchClearButtonIcon,
                    on: {
                      click: function (event) {
                        _vm.model.onClickSearchClearButton(event)
                      },
                    },
                  },
                  [
                    _c("sv-svg-icon", {
                      attrs: { iconName: "icon-searchclear", size: "auto" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.model.isEmpty,
              expression: "model.isEmpty",
            },
          ],
          class: _vm.model.cssClasses.emptyContainer,
        },
        [
          _c(
            "div",
            {
              class: _vm.model.cssClasses.emptyText,
              attrs: { "aria-label": _vm.model.emptyMessage },
            },
            [_vm._v(_vm._s(_vm.model.emptyMessage))]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.model.renderElements
        ? _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.model.isEmpty,
                  expression: "!model.isEmpty",
                },
              ],
              class: _vm.model.cssClasses.itemsContainer,
              attrs: { role: "listbox", id: _vm.model.elementId },
              on: {
                mousedown: function (event) {
                  event.preventDefault()
                },
                mousemove: _vm.mouseMove,
                keydown: function (event) {
                  _vm.model.onKeyDown(event)
                },
              },
            },
            _vm._l(_vm.model.renderedActions, function (item) {
              return _c("sv-list-item", {
                key: item.id,
                attrs: { item: item, model: _vm.model },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }