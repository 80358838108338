var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.model.isVisible,
          expression: "model.isVisible",
        },
      ],
      staticClass: "sv-popup",
      class: _vm.model.styleClass,
      attrs: { tabindex: "-1" },
      on: {
        keydown: function (event) {
          _vm.model.onKeyDown(event)
        },
        click: function () {
          _vm.model.clickOutside()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "sv-popup__container",
          style: {
            left: _vm.model.left,
            top: _vm.model.top,
            height: _vm.model.height,
            width: _vm.model.width,
            minWidth: _vm.model.minWidth,
          },
          on: { click: _vm.clickInside },
        },
        [
          _c(
            "div",
            { staticClass: "sv-popup__shadow" },
            [
              _vm.model.showHeader
                ? _c(_vm.model.popupHeaderTemplate, {
                    tag: "component",
                    attrs: { model: _vm.model },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "sv-popup__body-content" }, [
                !!_vm.model.title
                  ? _c("div", { staticClass: "sv-popup__body-header" }, [
                      _vm._v(_vm._s(_vm.model.title)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "sv-popup__scrolling-content" }, [
                  _c(
                    "div",
                    { staticClass: "sv-popup__content" },
                    [
                      _c(
                        _vm.model.contentComponentName,
                        _vm._b(
                          { tag: "component" },
                          "component",
                          _vm.model.contentComponentData,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.model.showFooter
                  ? _c(
                      "div",
                      { staticClass: "sv-popup__body-footer" },
                      [
                        _c("sv-action-bar", {
                          attrs: { model: _vm.model.footerToolbar },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }