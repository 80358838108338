



















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { QuestionMultipleTextModel } from "survey-core";

@Component
export class MultipleText extends QuestionVue<QuestionMultipleTextModel> {
}
Vue.component("survey-multipletext", MultipleText);
export default MultipleText;
