var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.question.getRadioItemClass(_vm.question.cssClasses, _vm.value),
      attrs: { role: "presentation" },
    },
    [
      _c("label", { class: _vm.question.cssClasses.radioLabel }, [
        _c("input", {
          class: _vm.question.cssClasses.itemRadioControl,
          attrs: {
            type: "radio",
            name: _vm.question.name,
            "aria-describedby": _vm.question.ariaDescribedBy,
            disabled: _vm.question.isInputReadOnly,
          },
          domProps: {
            value: _vm.value,
            checked: _vm.value === _vm.question.booleanValueRendered,
          },
          on: { change: _vm.handleChange },
        }),
        _vm._v(" "),
        _vm.question.cssClasses.materialRadioDecorator
          ? _c(
              "span",
              { class: _vm.question.cssClasses.materialRadioDecorator },
              [
                _vm.question.itemSvgIcon
                  ? _c(
                      "svg",
                      { class: _vm.question.cssClasses.itemRadioDecorator },
                      [
                        _c("use", {
                          attrs: { "xlink:href": _vm.question.itemSvgIcon },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          { class: _vm.question.cssClasses.radioControlLabel },
          [_c("survey-string", { attrs: { locString: _vm.locText } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }