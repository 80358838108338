var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.cssClasses.rootDropdown },
    [_c("sv-dropdown", { attrs: { question: _vm.question } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }