var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sv-brand-info" }, [
      _c(
        "a",
        {
          staticClass: "sv-brand-info__logo",
          attrs: {
            href: "https://surveyjs.io/?utm_source=built-in_links&utm_medium=online_survey_tool&utm_campaign=landing_page",
          },
        },
        [
          _c("img", {
            attrs: { src: "https://surveyjs.io/Content/Images/poweredby.svg" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "sv-brand-info__text" }, [
        _vm._v("Try and see how easy it is to "),
        _c(
          "a",
          {
            attrs: {
              href: "https://surveyjs.io/create-survey?utm_source=built-in_links&utm_medium=online_survey_tool&utm_campaign=create_survey",
            },
          },
          [_vm._v("create a survey")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sv-brand-info__terms" }, [
        _c("a", { attrs: { href: "https://surveyjs.io/TermsOfUse" } }, [
          _vm._v("Terms of Use & Privacy Statement"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }