import { render, staticRenderFns } from "./title-element.vue?vue&type=template&id=641d93d9&"
import script from "./title-element.vue?vue&type=script&lang=ts&"
export * from "./title-element.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/survey-library/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('641d93d9')) {
      api.createRecord('641d93d9', component.options)
    } else {
      api.reload('641d93d9', component.options)
    }
    module.hot.accept("./title-element.vue?vue&type=template&id=641d93d9&", function () {
      api.rerender('641d93d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/title/title-element.vue"
export default component.exports